<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { required, } from "vuelidate/lib/validators";
import Choices from "choices.js";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'


import { useSystemApi } from "@/apis/system";
import { useTaskApi } from "@/apis/task";


/**
 * Advanced table component
 */
export default {
  page: {
    title: '新建加工',
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    flatPickr,
    vueDropzone: vue2Dropzone,
    VueBootstrapTypeahead,

  },

  validations() {
    return {
      task_number: { required },
      p_model: { required },
      p_produce_count: { required },
    }
  },
  data() {
    return {
      title: this.$t('produce.new_task.sub_title'),
      items: [
        {
          text: this.$t('produce.new_task.nav_first_item'),
          href: "/",
        },
        {
          text: this.$t('produce.new_task.nav_second_item'),
          active: true,
        },
      ],

      has_data_error: false,
      data_err_msg: '',

      listingData: [],
      model_data: [],
      team_data: [],

      p_list_fields: [
        { key: 'Code', label: this.$t('produce.new_task.list_table_field.code') },
        { key: 'PNumber', label: this.$t('produce.new_task.list_table_field.pnumber') },
        { key: 'PCount', label: '投产数量' },
        { key: 'Team', label: '班组' },
        { key: 'Leader', label: '责任人' },
        { key: 'Designer', label: '设计人' },
        { key: 'Coordinator', label: '协调人' },
        { key: 'Standard', label: '执行标准' },
        { key: 'E', label: 'E' },
        { key: 'Action', label: '操作' },
      ],

      task_number  : '',
      t_fields     : {},

      p_model      : '',
      p_model_name : '',
      p_number     : '',
      p_team                : '',
      p_assemble_count      : '',
      p_produce_count       : '',
      p_produce_total_count : '',

      project_date          : '',
      p_project             : '',

      pre_person_role : [{key : 'responser', role : '责任人'} , {key : 'designer', role : '设计人'}, {key : 'coordinator', role : '协调人'}, {key : 'initator', role : '发起人'}],
      p_persons: {},

      p_fields : {},


      dropzoneRegularOptions: {
        url: "/api/v1/task/upload_produces?form_type=1",
        thumbnailWidth: 150,
        maxFilesize: 1,
      },

      dropzoneIrregularOptions: {
        url: "/api/v1/task/upload_produces?form_type=0",
        thumbnailWidth: 150,
        maxFilesize: 1,
      },



    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return 0
    },


  },
  mounted() {




    useSystemApi().list_system_conf('DEPT').then(res => {

      if (res.err == 0) {
        res.data.map(e => {
          this.team_data.push({ value: e.key_field, label: e.value_field, id: e.id })
        })

        this.team_data[0].selected = true
        this.p_team = this.team_data[0].value_field

        new Choices('#team_choice', { choices: this.team_data })

      }

    })





    useTaskApi().new_task_sn().then(res => {
      if (res.err == 0) {
        this.task_number = res.data.sn
      }
    })

  },
  methods: {

    formSubmit() {

      let data = {
        task_number  : this.task_number,
        fields       : this.t_fields,
        produces: []
      }

      this.listingData.map(e => {
        data.produces.push({
          model    : e.code,
          number   : e.pcount,
          p_number : e.pnumber,
          d_name   : e.dept_name,
          dept     : e.dept,
          
          persons  : e.persons,
          fields   : e.fields
        })
      })


      useTaskApi().add_task(data).then(res => {
        if (res.err == 0) {

          this.$alertify.message("创建成功");
          this.$router.push({ name: 'produce-task-list' })
        } else {
          this.$alertify.error("创建失败 " + res.err + ":" + res.msg);
        }
      })
    },

    onAddPlist() {
      this.$v.$touch()

      if (this.$v.$invalid == false) {

        let persons = []

        for(let p  of this.pre_person_role) {
          let person = this.p_persons[p.key]

          persons.push({
            key       : p.key,
            user_role : p.role,
            user_name : person? person : 'N/A'
          })
        }


        let dept_name = 'N/A'
        if (this.p_team) {
          let td = this.team_data.find(e => e.value == this.p_team)
          if (td) {
            dept_name = td.label
          } 
        } else {
          let td = this.team_data.find(e => e.selected == true)
          if (td) {
            dept_name   = td.label
            this.p_team = td.value
          } else {
            this.p_team   = this.team_data[0].value
          }
         
        }

        this.listingData.push({
          code     : this.p_model,
          pnumber  : this.p_number,
          dept_name: dept_name,
          dept     : this.p_team,
          pcount   : this.p_produce_count,
          acount   : this.p_assemble_count,
          persons : persons,
          fields  : Object.assign({}, this.fields)

        })

        this.p_produce_total_count = this.listingData.reduce((a, c) => a += Number(c.pcount), 0)
      }

    },


    removeProduce(meta) {
      this.listingData.splice(meta.index, 1)
    },

    get_p_person(item, key) {
      let p = item.persons.find(e => e.key == key)
      if (p) {
        return p.user_name
      } else {
        ''
      }
    },

    fileUploaded(evt, resp) {
      console.log(evt, resp)
      if (resp.err == 0) {
        resp.data.map(e => {
          if (e.err) {
             e._rowVariant   = 'danger'
          }
          this.listingData.push(e)
          
        })
      }
    },

    search_model(searched_str) {
      useSystemApi().model_search('MODEL', searched_str).then(res => {
        if (res.err == 0) {
          this.model_data = []
          res.data.map(e => {
            this.model_data.push(e.key_field)
          })
        }

      })
    }
  },

  watch: {
    p_model: function (new_val) {
      this.search_model(new_val)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <form class="needs-validation" @submit.prevent="formSubmit">
          <div class="card">


            <div class="card border shadow-none mb-3">
              <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-sm">
                    <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                      01
                    </div>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h5 class="card-title">{{ $t('produce.new_task.section_1_title') }}</h5>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-2">
                    <label class="form-label">任务编号</label>
                    <div class="mb-3">
                      <input class="form-control" v-model="task_number" :class="{ 'is-invalid': $v.task_number.$error }"
                        readonly />
                      <div v-if="$v.task_number.$error" class="invalid-feedback">
                        <span v-if="!$v.task_number.required">{{ $t('produce.new_task.err.required') }}</span>
                      </div>
                    </div>

                  </div>
                  <div class="col-md-2">
                    <label class="form-label">项目</label>
                    <input class="form-control" v-model="t_fields.project" />
                  </div>

                  <div class="col-md-2">
                    <label class="form-label">预计完成时间</label>
                    <flat-pickr v-model="t_fields.plan_date" placeholder="Select a date" class="form-control"></flat-pickr>

                  </div>


                  <div class="col-md-4">
                    <label class="form-label">说明</label>
                    <div class="mb-3">
                      <input class="form-control" v-model="t_fields.remarks" 
                        maxlength="50"/>
                    </div>

                  </div>

                </div>
                <!-- end row-->
               

              </div>
            </div>
            <!-- end section 1 -->


            <div class="card border shadow-none mb-3">
              <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-sm">
                    <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                      02
                    </div>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h5 class="card-title">{{ $t('produce.new_task.section_2_title') }}</h5>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-8">
                    <div class="row mb-3">

                      <div class="col-md-3">
                        <label class="form-label">加工零件型号</label>
                        <div class="mb-3">
                          <vue-bootstrap-typeahead v-model="p_model" :data="model_data" autocomplete="off"
                            :inputClass="{ 'is-invalid': $v.p_model.$error }" />
                          <div v-if="$v.p_model.$error" class="invalid-feedback">
                            <span v-if="!$v.p_model.required">{{ $t('produce.new_task.err.required') }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <label class="form-label">名称</label>
                        <div class="mb-3">
                          <input class="form-control" v-model="p_model_name" />
                        </div>
                      </div>


                      <div class="col-md-2">
                        <label class="form-label">批号</label>
                        <input class="form-control" v-model="p_number" />
                      </div>
                      <div class="col-md-2">
                        <label class="form-label">单套数量</label>
                        <input class="form-control" v-model="p_assemble_count" />

                      </div>

                      <div class="col-md-2">
                        <label class="form-label">投产数量</label>
                        <input class="form-control" v-model="p_produce_count"
                          :class="{ 'is-invalid': $v.p_produce_count.$error }" />
                        <div v-if="$v.p_produce_count.$error" class="invalid-feedback">
                          <span v-if="!$v.p_produce_count.required">{{ $t('produce.new_task.err.required') }}</span>
                        </div>

                      </div>

                     

                    </div>
                    <!-- end row-->

                    <div class="row mb-3">
                      <div class="col-md-3">
                        <label class="form-label">班组</label>
                        <select id="team_choice" v-model="p_team"></select>

                      </div>

                      <div class="col-md-2">
                        <label class="form-label">责任人</label>
                        <input class="form-control" v-model="p_persons.responser" />

                      </div>
                      <div class="col-md-2">
                        <label class="form-label">设计人</label>
                        <input class="form-control" v-model="p_persons.designer" />

                      </div>
                      <div class="col-md-2">
                        <label class="form-label">计划协调员</label>
                        <input class="form-control" v-model="p_persons.coordinator" />

                      </div>
                    </div>
                     <!-- end row-->


                    <div class="row mb-5 ">


                     
                    </div>

                    <div class="row mb-3">

                      <div class="col-md-3">
                        <label class="form-label">材料</label>
                        <input class="form-control" v-model="p_fields.material" />

                      </div>
                      <div class="col-md-2">
                        <label class="form-label">工艺</label>
                        <input class="form-control" v-model="p_fields.tech" />

                      </div>
                      <div class="col-md-3">
                        <label class="form-label">执行标准</label>
                        <input class="form-control" v-model="p_fields.iso_standard" />

                      </div>
                      <div class="col-md-2">
                        <label class="form-label">级别</label>
                        <input class="form-control" v-model="p_fields.level" />

                      </div>
                    </div>
                    <div class="row mb-3">

                      <div class="col-md-12">
                        <label class="form-label">备注</label>
                        <input class="form-control" v-model="p_fields.remarks" maxlength="100" />

                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="d-flex flex-wrap gap-2 justify-content-center d-flex align-items-center">
                          <button type="button" class="btn btn-secondary w-sm" @click="onAddPlist"> {{
                            $t('produce.new_task.btn.add') }} </button>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="row mb-3">
                      <div class="col-md-12">
                        <vue-dropzone id="irregural_dropzone" ref="myVueDropzone" :use-custom-slot="true"
                          :options="dropzoneIrregularOptions" @vdropzone-success="fileUploaded">
                          <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>拖拽 (非准件产品) 清单并上传.</h4>
                          </div>
                        </vue-dropzone>
                      </div>
                    </div>
                    <!-- end row-->

                    <div class="row">
                      <div class="col-md-12">
                        <vue-dropzone id="regural_dropzone" ref="myVueDropzone" :use-custom-slot="true"
                          :options="dropzoneRegularOptions" @vdropzone-success="fileUploaded">
                          <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>拖拽 (准件产品) 清单并上传.</h4>
                          </div>
                        </vue-dropzone>
                      </div>
                    </div>
                    <!-- end row-->
                  </div>
                </div>




              </div>
            </div>
            <!-- end section 2 -->


            <div class="card border shadow-none mb-5">
              <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-sm">
                    <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                      02
                    </div>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h5 class="card-title">{{ $t('produce.new_task.section_3_title') }}</h5>
                </div>
              </div>
              <div class="card-body">
                <div data-simplebar>
                  <div class="table-responsive mt-3">
                    <div class="table align-middle table-nowrap">
                      <b-table :items="listingData" :fields="p_list_fields" responsive="sm"
                        :per-page="listingData.length" class="table-check">
                        <template #cell(Code)="data">
                          {{ data.item.code }}
                        </template>
                        <template #cell(PNumber)="data">
                          {{ data.item.pnumber }}
                        </template>

                        <template #cell(Team)="data">
                          {{ data.item.dept_name }}
                        </template>
                        <template #cell(Leader)="data">
                          {{ get_p_person(data.item, 'responser') }}
                        </template>
                        <template #cell(Designer)="data">
                          {{ get_p_person(data.item, 'designer') }}
                        </template>
                        <template #cell(Coordinator)="data">
                          {{ get_p_person(data.item, 'coordinator') }}
                        </template>



                        <template #cell(PCount)="data">
                          {{ data.item.pcount }}
                        </template>
                        <template #cell(Standard)="data">
                          {{ data.item.fields.standard }}
                        </template>


                        <template #cell(E)="data">
                          {{ data.item.msg }}
                        </template>

                        

                        <template #cell(Action)="data">
                          <b-dropdown variant="white" toggle-class="p-0">
                            <template v-slot:button-content>
                              <i class="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
                            </template>
                            <li>
                              <b-dropdown-item @click="removeProduce(data)"><i
                                  class="mdi mdi-pencil font-size-16 text-success me-1"></i> 移除
                              </b-dropdown-item>
                            </li>
                          </b-dropdown>
                        </template>

                      </b-table>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="d-flex flex-wrap gap-2 justify-content-end d-flex align-items-right">
                      <button type="button" class="btn btn-secondary w-sm"
                        @click="$router.push({ name: 'task-list' })"> {{
                          $t('produce.new_task.btn.cancel') }} </button>
                      <button type="submit" class="btn btn-primary w-sm"> {{ $t('produce.new_task.btn.submit')
                        }}</button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <!-- end card -->





          </div>
        </form>
      </div>
    </div>

  </Layout>
</template>
